import React, { Component } from 'react';

import config from '../config.json';

class HeaderSlide extends Component {
    constructor() {
        super();
        this.state = {
            imageUrl: ''
        };
    }

    componentDidMount() {
        this.updateBackgroundImage();
        window.addEventListener('resize', this.updateBackgroundImage);  // Actualizar si la ventana cambia de tamaño
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateBackgroundImage);  // Limpiar el event listener al desmontar
    }

    updateBackgroundImage = () => {
        let imagePath = '';
        const windowWidth = window.innerWidth;

        // Definir la URL dependiendo del tamaño de la pantalla
        if (windowWidth < 500) {
            imagePath = `https://storage.googleapis.com/${config.FIREBASE_STORAGE_BUCKET}/portada/sizeS/portada.webp`;
        } else if (windowWidth >= 500 && windowWidth < 1270) {
            imagePath = `https://storage.googleapis.com/${config.FIREBASE_STORAGE_BUCKET}/portada/sizeM/portada.webp`;
        } else if (windowWidth >= 1270 && windowWidth < 1500) {
            imagePath = `https://storage.googleapis.com/${config.FIREBASE_STORAGE_BUCKET}/portada/sizeL/portada.webp`;
        } else {
            imagePath = `https://storage.googleapis.com/${config.FIREBASE_STORAGE_BUCKET}/portada/sizeXL/portada.webp`;
        }

        // Actualizamos el estado con la URL de la imagen correcta
        this.setState({
            imageUrl: imagePath
        });
    };

    scrollToSection = () => {
        var element = document.getElementById("scroll-button");
        var rect = element.getBoundingClientRect();
        var currentPosition = document.documentElement.scrollTop;
        if (currentPosition === 0) {
            currentPosition = document.body.scrollTop;
        }
        currentPosition += rect.top + 70;

        window.scrollTo({
            top: currentPosition,
            left: 0,
            behavior: 'smooth'
        });
    }

    render() {
        const { imageUrl } = this.state;

        return (
            <section className="home">
                <a href="/shop" className="btn-tienda">VER TIENDA</a>
        
                <div className="slider-container">
                    <div className="tp-banner-container">
                        <div className="tp-banner" >
                            <ul>
                                <li data-transition="fade" data-slotamount="1" data-masterspeed="500"  data-saveperformance="on"  data-title="Intro Slide">
                                    {/* Aplicando la imagen de fondo de forma dinámica */}
                                    <div
                                        className="portada portadaImage"
                                        style={{
                                            backgroundImage: `url(${imageUrl})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center'
                                        }}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HeaderSlide;
